import httpService from '../utils/http'

const Api = {
    CreateOrder: 'yp/pay',
    SyncNotify: 'yp/sync-notify'
}

/**
 *
 * @param data
 * @returns {AxiosPromise}
 */
export function createOrder (data) {
    return httpService({
        url: Api.CreateOrder,
        method: 'POST',
        data:data
    })
}

/**
 *
 * @param data
 * @returns {AxiosPromise}
 */
export function syncNotify (data) {
    return httpService({
        url: Api.SyncNotify,
        method: 'POST',
        data:data
    })
}

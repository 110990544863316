<template>
    <div class="payment">
        <van-cell-group class="payment-page">
            <van-cell class="payment-page-cell">开票需缴税费</van-cell>
            <van-cell class="payment-page-amount">￥ {{ invoiceDetail.total_tax_amount }}</van-cell>
            <van-cell class="payment-page-button">
                <van-button style="border-radius: 4px;" block type="warning" class="submit-button-btn" @click="postCreateOrder" :disabled="no_submit"  native-type="submit">
                    确定支付
                </van-button>
            </van-cell>
          <van-cell class="payment-page-tips">请先支付税费，开票申请才能提交成功。<br>一个小时未支付，申请单自动作废。</van-cell>
        </van-cell-group>
    </div>
</template>
<script>

    import {createOrder,syncNotify} from "../../api/order";
    import {InvoiceDetail} from "../../api/invoice";

    export default {
      data(){
          return {
            payData: {},
            invoiceDetail: {},
            no_submit:false,
          }
      },
      created() {
        this.getInvoiceDetail()
      },
      methods:{
        getInvoiceDetail(){
          let _self = this;
          let id = _self.$route.query.id;

          InvoiceDetail({id:id}).then(function (res){
            _self.invoiceDetail = res.Data;
          }).catch(function (err){
            console.log(err)
          })
        },
        postCreateOrder(){
          let _self = this;
          let id = _self.$route.query.id;
          let user = JSON.parse(localStorage.getItem('user'));
          let open_id = localStorage.getItem('open_id');
          _self.no_submit = true;
          // 三秒生效
          setTimeout(function(){
            _self.no_submit = false;
          }, 4000);
          if(!open_id){
            open_id = user.open_id
          }
          createOrder({invoice_id:id,openId:open_id}).then(function (res){
            _self.payData = res.Data;
            _self.pay();
          }).catch(function (err){
            console.log(err)
          })
        },
        /* eslint-disable */
        onBridgeReady(){
          let _self = this;
          let id = _self.$route.query.id;
          WeixinJSBridge.invoke('getBrandWCPayRequest', {
                "appId": _self.payData.appId,//公众号ID，由商户传入
                "timeStamp": _self.payData.timeStamp,//时间戳，自1970年以来的秒数
                "nonceStr": _self.payData.nonceStr,//随机串
                "package": _self.payData.packageData,
                "signType": _self.payData.signType,//微信签名方式：
                "paySign": _self.payData.paySign, //微信签名
              },
              function (res) {
                if (res.err_msg == "get_brand_wcpay_request:ok") {
                  // 使用以上方式判断前端返回,微信团队郑重提示：
                  //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                  // 同步通知
                  syncNotify({id:id}).then((r)=>{
                    console.info(r)
                  })
                }
              });
        },
        pay(){
          let _self = this;
          if (typeof WeixinJSBridge == "undefined") {
            if (document.addEventListener) {
              document.addEventListener('WeixinJSBridgeReady', _self.onBridgeReady, false);
            } else if (document.attachEvent) {
              document.attachEvent('WeixinJSBridgeReady',_self.onBridgeReady);
              document.attachEvent('onWeixinJSBridgeReady', _self.onBridgeReady);
            }
          } else {
            _self.onBridgeReady();
          }
        }
        /* eslint-disable */
      }
    }
</script>


<style lang="less" scoped>
  .payment{
    height:100vh;
    background-color:#fff;
    :deep(&-page){
      padding-top: 10vh;
    }
    :deep(&-page-cell){
      text-align: center;
    }
    :deep(&-page-amount){
      font-size: 28px;
      font-weight: 600;
    }
    :deep(&-page-button){
      margin-top: 8vh;
    }
    :deep(&-page-tips){
      font-size: 12px;
      .van-cell__value--alone{
        color: #999!important;
      }
    }
    :deep(.van-cell::after){
      border-bottom: unset;
    }
    :deep(.van-cell__value){
      text-align: center;
    }
    :deep(.submit-button-btn){
      margin: 0 auto;
      width: 60vw;
      background-color:#ff552e;
    }
    :deep(.van-hairline--top-bottom::after, .van-hairline-unset--top-bottom::after){
      border: unset;
    }
  }
</style>

import request from '../utils/request'

const Api = {
    Invoice: 'tax/list',
    InvoiceDetail: 'tax/detail',
    GoodsDetail: 'tax/goods-detail',
    Payment: 'tax/payment-tax',
    PayTax: 'tax/pay-taxes',
    Exclude: 'tax/pay-exclude',//这个月不含税金额
    Pick: 'tax/pick-up',//我已领取
}

/**
 *
 * @param data
 * @returns {AxiosPromise}
 */
export function Invoice (data) {
    return request({
        url: Api.Invoice,
        method: 'POST',
        params:data
    })
}


/**
 *
 * @param data
 * @returns {AxiosPromise}
 */
export function InvoiceDetail (data) {
    return request({
        url: Api.InvoiceDetail,
        method: 'POST',
        data:data
    })
}

/**
 *
 * @param data
 * @returns {AxiosPromise}
 */
export function GoodsDetail (data) {
    return request({
        url: Api.GoodsDetail,
        method: 'POST',
        data:data
    })
}

/**
 *
 * @param data
 * @returns {AxiosPromise}
 */
export function Payment (data) {
    return request({
        url: Api.Payment,
        method: 'POST',
        data:data
    })
}

/**
 *
 * @param data
 * @returns {AxiosPromise}
 */
export function PayTax (data) {
    return request({
        url: Api.PayTax,
        method: 'POST',
        data:data
    })
}

/**
 *
 * @param data
 * @returns {AxiosPromise}
 */
export function Exclude (data) {
    return request({
        url: Api.Exclude,
        method: 'GET',
        params:data
    })
}

/**
 *
 * @param data
 * @returns {AxiosPromise}
 */
export function Pick (data) {
    return request({
        url: Api.Pick,
        method: 'POST',
        data:data
    })
}
